<template>
    <div v-if="ready == true">
        <div class="list-page-header do-not-print-me" v-if="!hideToolbar">
            <div class="list-header-left">
                <div class="breadcrumb">
                    <p>
                        <span v-if="header == 'Clients'" class="page-title">Members</span>
                        <span v-else class="page-title">{{ header }}</span>
                    </p>
                </div>
                <div class="flex list-search" v-if="!hideSearch">
                    <input
                        type="text"
                        v-model="searchTextRaw"
                        placeholder="Search"
                        ref="searchInput"
                        @blur="handleBlur"
                    />
                    <!-- <button @click="search" type="button">Search</button> -->
                </div>
                <button
                    class="primary list-create"
                    v-if="createButton && pageMeta.permissions.create"
                    @click="addNewRecord"
                >
                    + Create New
                </button>
                <!-- Potentially convert this into a component -->
                <div>
                    <slot name="control" />
                </div>
            </div>

            <div class="list-header-right">
                <div class="speed-date-range-wrapper" v-if="!hide_speed_date">
                    <SpeedDate :default_date_range="criteria.default_date_range" @redate="redate" />
                </div>

                <div class="reset-all-criteria" v-if="!hideReset">
                    <button @click="clear_criteria" type="button" class="no-bg reset-all-criteria-button">
                        Reset View
                    </button>
                </div>

                <div class="filter-wrap" v-if="!hideFilter">
                    <button @click="openFilter = !openFilter" type="button" class="no-bg filter-button">Filter</button>
                    <FilterList
                        :filters="!blockFilters ? JSON.parse(pageMeta.filters) : {}"
                        @getFilters="modifyCriteria"
                        @closeFilter="(close) => (this.openFilter = close)"
                        v-if="openFilter"
                        :selectedFilters="criteria.filter"
                        class="filters-container"
                        id="filter-menu"
                    />
                </div>

                <div class="hide-show-columns-button" v-if="!hideColumn">
                    <button @click="openColumn = !openColumn" type="button" class="no-bg">Columns</button>
                    <ColumnList
                        :selected_columns="criteria.column ? criteria.column : []"
                        @getColumns="modifyCriteria"
                        @closeColumn="(close) => (this.openColumn = close)"
                        v-if="openColumn"
                        :columns="hide_show_columns"
                        class="filters-container"
                        id="column-menu"
                    />
                </div>

                <div class="list-view-button" v-if="0 && !hideView">
                    <button class="no-bg">List View</button>
                </div>

                <div class="actions-wrap" v-if="!hideAction">
                    <button @click="show_hide_actions" class="actions-button no-bg">Actions</button>
                    <div class="actions-menu dropdown-menu" :class="actions_drop_down">
                        <div v-if="0" class="action-button">
                            <button
                                class="no-bg"
                                v-if="createAction && target_page && pageMeta.permissions.create"
                                @click="addNewRecord"
                            >
                                + Create New
                            </button>
                        </div>
                        <div class="action-button">
                            <button class="no-bg" @click="export2exel">Export to Excel</button>
                        </div>
                        <div class="action-button">
                            <button class="no-bg" @click="export2csv">Export to CSV</button>
                        </div>
                        <div class="action-button">
                            <button class="no-bg" v-if="roleAccess" @click="exportCredentials2csv">
                                Export credentials to CSV
                            </button>
                        </div>
                        <div class="action-button">
                            <button class="no-bg" @click="printList">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-list-grid-standard">
            <ejs-grid
                ref="grid"
                :data-source="formattedRowData"
                :allowFiltering="true"
                :allowSorting="true"
                :enableVirtualization="true"
                :rowSelected="onRowSelected"
                :rowDeselected="onRowDeselected"
                :actionComplete="actionHandler"
                :actionBegin="actionHandler"
                :sortSettings="sortOptions"
                :aria-rowcount="100"
                :allowPaging="false"
                :pageSettings="{ pageSize: 100 }"
                :selectionSettings="selectionOptions"
                :commandClick="commandClick"
                :rowDataBound="handleRowDataBound"
                @queryCellInfo="queryCellInfoHandler"
                v-if="on"
            >
                <e-columns>
                    <e-column
                        v-for="column in formattedColumnMeta"
                        :key="column.field"
                        :field="column.field"
                        :headerText="column.headerText"
                        :allowSorting="column.allowSorting"
                        :editType="column.editType"
                        :type="column.type"
                        :displayAsCheckBox="column.displayAsCheckBox"
                        :textAlign="column.align"
                        :headerTextAlign="column.headerAlign"
                        :width="column.width"
                        :format="formatType(column.field)"
                    ></e-column>

                    <!--  <e-column field="Commands" headerText="" width="150" :commands="commands"></e-column>-->
                    <!--  <e-column field="Commands" headerText="" width="150" :commands="commands2"></e-column>-->

                    <e-column
                        v-for="column in commandColumns"
                        :key="column.field"
                        :field="column.field"
                        :headerText="column.headerText"
                        :commands="column.commands"
                    ></e-column>
                </e-columns>
            </ejs-grid>
        </div>
        <paginator
            :total_count="totalCount"
            v-if="!hidePaginator"
            :pageSize="criteria.page && criteria.page.num_per_page ? criteria.page.num_per_page : 10"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="$emit('cancelOverride')"
        />
        <div v-if="!hideSelectColumn">
            <button @click="handleCancel">Cancel</button>
            <button @click="handleAdd">Add</button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { debounce } from 'lodash';
    import { GridPlugin, Sort, VirtualScroll, CommandColumn } from '@syncfusion/ej2-vue-grids';
    import Paginator from '@/components/general/paginator/paginator.vue';
    import FilterList from '@/components/general/filter/filter.vue';
    import ColumnList from '@/components/general/column/column.vue';
    import SpeedDate from '@/components/general/inputs/SpeedDate';
    import { companies, ledger } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import { Roles } from '@/util/globalConstants';
    Vue.use(GridPlugin);

    function makeAssociative(assocCol, data) {
        return data.reduce((out, cur) => {
            out[cur[assocCol]] = cur;
            return out;
        }, {});
    }

    export default {
        components: { Paginator, FilterList, ColumnList, SpeedDate, CommandColumn, ledger },
        props: {
            headerText: {
                type: String,
                default: null,
            },
            preventNavOnColLabel: {
                type: String,
                default: null,
            },
            preventNavOnColIndexes: {
                type: Array,
                default: () => [],
            },
            data_source: {
                type: Array,
                default: () => [],
            },
            export_api: {
                type: String,
                default: '/invalid_api',
            },
            totalCount: {
                type: Number,
                default: 0,
            },
            column_meta: {
                type: Array,
                required: false,
            },
            target_page: {
                type: String,
            },
            loadingColumns: {
                type: Boolean,
            },
            criteriaProps: {
                type: Object,
            },
            createButton: {
                type: Boolean,
                default: false,
            },
            createAction: {
                type: Boolean,
                default: true,
            },
            hideSelectColumn: {
                type: Boolean,
                default: true,
            },
            hideSearch: {
                type: Boolean,
                default: false,
            },
            hideFilter: {
                type: Boolean,
                default: false,
            },
            hideColumn: {
                type: Boolean,
                default: false,
            },
            hideView: {
                type: Boolean,
                default: false,
            },
            hideAction: {
                type: Boolean,
                default: false,
            },
            hideToolbar: {
                type: Boolean,
                default: false,
            },
            hideReset: {
                type: Boolean,
                default: false,
            },
            hidePaginator: {
                type: Boolean,
                default: false,
            },
            hide_speed_date: {
                type: Boolean,
                default: true,
            },
            preFormattedData: {
                type: Boolean,
                default: false,
            },
            ignoreDateConversion: {
                type: Boolean,
                default: false,
            },
            /*initSelectedIndexes: {
                type: Array,
                default: () => [],
            },*/
            storeKey: {
                type: String,
                default: 'invalid_key',
            },
            useOverride: {
                type: Boolean,
                default: false,
            },
            commandColumns: {
                type: Array,
                default: () => [],
            },
            rowDataBound: {
                type: Function,
                default: null,
            },
            client_id: {
                type: [String, Number],
                default: 0,
            },
            blockFilters: {
                type: Boolean,
                default: false,
            },
        },
        name: 'ChirpListClient',
        data() {
            return {
                canCreate: 1,
                searchBar: null,
                openFilter: false,
                openColumn: false,
                currentPage: 1,
                sort: {},
                ready: false,
                on: true,
                actions_drop_down: '',
                searchTextRaw: '',
                hide_show_columns: [],
                selectionOptions: {
                    mode: 'Both',
                    enableToggle: true,
                },
                selectedRecords: [],
                export_source: [],
                roleAccess: false,
                Roles,
                // commands: [
                //     {
                //         buttonOption: { content: "Details", cssClass: "e-flat custombutton" },
                //
                //     },
                //     // {
                //     //     buttonOption: { content: "STUFF", cssClass: "e-flat custombutton" },
                //     //
                //     // }
                // ],
                // commands2: [
                //     // {
                //     //     buttonOption: { content: "Details", cssClass: "e-flat custombutton" },
                //     //
                //     // },
                //     {
                //         buttonOption: { content: "STUFF", cssClass: "e-flat custombutton" },
                //
                //     }
                // ],
            };
        },
        computed: {
            criteria() {
                let crits = this.useOverride ? this.overrideCriteria : this.savedCriteria;
                if (!crits.page) {
                    crits.page = {
                        current_page: 1,
                        num_per_page: 10,
                    };
                }
                if (!crits.page.current_page) {
                    crits.page.current_page = 1;
                }
                return crits;
            },
            header() {
                return this.headerText || this.pageMeta.title;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            sortOptions: {
                get() {
                    const sortKeys = Object.keys(this.criteria?.sort || {});
                    return {
                        columns: sortKeys.map((key) => ({
                            field: key,
                            direction: this.criteria?.sort[key] || 'Ascending',
                        })),
                    };
                },
                set(obj) {
                    return obj;
                },
            },
            searchText() {
                return this.criteria?.search?.like_all || '';
            },
            formattedRowData() {
                if (this.preFormattedData) {
                    return this.data_source;
                }

                let formattedData = [...this.data_source];

                for (const [index, row] of Object.entries(formattedData)) {
                    for (const [column, value] of Object.entries(row)) {
                        let lowerColumn = column.toLowerCase();

                        if (
                            lowerColumn.includes('total') ||
                            lowerColumn.includes('amount') ||
                            lowerColumn.includes('cost') ||
                            lowerColumn.includes('price') ||
                            lowerColumn.includes('dollars') ||
                            lowerColumn.includes('balance')
                        ) {
                            if (typeof formattedData[index][column] === 'number')
                                formattedData[index][column] = value / 100;
                        }

                        if (
                            (lowerColumn.includes('date') || lowerColumn.includes('dayt')) &&
                            !this.ignoreDateConversion
                        ) {
                            formattedData[index][column] = this.getShortDate(value);
                        }
                    }
                }

                return formattedData;
            },
            formattedColumnMeta() {
                // let columns = this.column_m
                return this.column_maker();
            },
        },
        methods: {
            async init() {
                let roleId = JSON.parse(localStorage.getItem('cn-user')).role_id;
                if ([Roles.ADMIN, Roles.SUPER_ADMIN].includes(roleId)) {
                    this.roleAccess = true;
                }
            },
            handleRowDataBound(args) {
                if (this.rowDataBound) {
                    this.rowDataBound(args);
                }
            },
            commandClick: function (args) {
                this.$emit('buttonInCellClicked', args);
            },
            handleCancel() {
                this.clearSelection();
                //this.$refs.grid.selectRows(this.initSelectedIndexes);
            },
            clearSelection() {
                this.selectedRecords = [];
                this.$refs.grid.clearSelection();
            },
            handleAdd() {
                this.$emit('addSelectedRecords', this.selectedRecords);
            },
            column_maker() {
                const columnMeta = [];
                this.hide_show_columns = [];
                let rec2push, meta_man, ok2push;

                if (this.column_meta) {
                    meta_man = makeAssociative('field', this.column_meta);
                }

                if (!this.hideSelectColumn) {
                    columnMeta.push({
                        type: 'checkbox',
                    });
                }

                if (this.data_source && this.data_source.length) {
                    const dataSourceModel = this.data_source[0];

                    for (const key in dataSourceModel) {
                        if (key !== 'id' && !this.criteria?.column?.includes(key)) {
                            ok2push = 1;
                            rec2push = {
                                field: key,
                                headerText: this.convertStringToTitleCase(key),
                                allowFiltering: true,
                                allowSorting: !['dashboardNames', 'tags'].includes(key),
                            };
                            if (meta_man && meta_man[key]) {
                                if (meta_man[key].hide || meta_man[key].hidden) {
                                    ok2push = 0;
                                } else {
                                    for (const [k, v] of Object.entries(meta_man[key])) {
                                        rec2push[k] = v;
                                    }
                                }
                            }
                            if (ok2push) {
                                columnMeta.push(rec2push);
                            }

                            this.hide_show_columns.push({ label: rec2push.headerText, name: rec2push.field });
                        }
                    }
                } else {
                    if (this.column_meta) {
                        for (const metaObj of this.column_meta) {
                            if (metaObj.field !== 'id' && !this.criteria?.column?.includes(metaObj.field)) {
                                columnMeta.push(metaObj);
                            }
                        }
                    }
                }

                if (this.column_meta) {
                    this.hide_show_columns = this.column_meta
                        .filter((element) => {
                            if (element.field == 'id') return false;
                            return true;
                        })
                        .map((element) => {
                            return { label: element.headerText, name: element.field };
                        });
                }

                return columnMeta;
            },
            handlePageChange(newPage) {
                this.currentPage = newPage;
            },
            show_hide_actions() {
                if (this.actions_drop_down == '') {
                    this.actions_drop_down = 'show_actions_drop_down';
                } else {
                    this.actions_drop_down = '';
                }
            },
            convertStringToTitleCase(str) {
                str = str.toString().replaceAll('_', ' ');
                return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                    return $1.toUpperCase();
                });
            },
            closeFilter(data) {
                this.openFilter = data;
            },
            closeColumn(data) {
                this.openColumn = data;
            },
            formatType(field) {
                if (
                    field?.includes('total') ||
                    field?.includes('amount') ||
                    field?.includes('cost') ||
                    field?.includes('price') ||
                    field?.includes('dollars') ||
                    field?.includes('balance')
                ) {
                    return 'C2';
                }
                return '';
            },
            autoFormatDataValues() {
                let result = this.data_source;
                if (result.length) {
                    if (this.criteria.column && this.criteria.column.length > 0) {
                        let columnsToHide = this.criteria.column;
                        result = this.data_source.map((element) => {
                            let elementTemp = {};
                            for (let key in element) {
                                if (columnsToHide.indexOf(key) == -1) {
                                    elementTemp[key] = element[key];
                                }
                            }
                            return elementTemp;
                        });
                    }

                    for (const [k, v] of Object.entries(result)) {
                        for (const [k2, v2] of Object.entries(v)) {
                            let field = k2.toLowerCase();
                            if (
                                field.includes('total') ||
                                field.includes('amount') ||
                                field.includes('cost') ||
                                field.includes('price') ||
                                field.includes('dollars') ||
                                field.includes('balance')
                            ) {
                                result[k][k2] = this.$getCurrency(v2);
                            }
                            if ((field.includes('date') || field.includes('dayt')) && !this.ignoreDateConversion) {
                                result[k][k2] = this.getShortDate(v2);
                            }
                        }
                    }
                }
                return result;
            },
            autoFormatExport(source) {
                let result = source;
                if (result.length) {
                    if (this.criteria.column && this.criteria.column.length > 0) {
                        let columnsToHide = this.criteria.column;
                        result = source.map((element) => {
                            let elementTemp = {};
                            for (let key in element) {
                                if (columnsToHide.indexOf(key) == -1) {
                                    elementTemp[key] = element[key];
                                }
                            }
                            return elementTemp;
                        });
                    }

                    for (const [k, v] of Object.entries(result)) {
                        for (const [k2, v2] of Object.entries(v)) {
                            let field = k2.toLowerCase();
                            if (
                                field.includes('total') ||
                                field.includes('amount') ||
                                field.includes('cost') ||
                                field.includes('price') ||
                                field.includes('dollars') ||
                                field.includes('balance')
                            ) {
                                result[k][k2] = this.$getCurrency(v2);
                            }
                            if ((field.includes('date') || field.includes('dayt')) && !this.ignoreDateConversion) {
                                result[k][k2] = this.getShortDate(v2);
                            }
                        }
                    }
                }
                return result;
            },
            getShortDate(isoDateString) {
                return dayjs(isoDateString).format('MM/DD/YY');
            },
            onRowSelected(args) {
                this.pushToSelectedRecords(args.data);
                if (this.preventNavOnColIndexes.includes(args.target?.cellIndex)) {
                    this.$emit('specialCellClicked', {
                        data: args.data,
                        columnIndex: args.target.cellIndex,
                    });

                    return;
                }
                if (this.preventNavOnColLabel && typeof args.target?.ariaLabel == 'string') {
                    let s = String(args.target?.ariaLabel).toLowerCase().trim();

                    if (s.includes(this.preventNavOnColLabel.toLowerCase().trim())) {
                        this.$emit('specialCellClicked', {
                            data: args.data,
                            columnIndex: args.target.cellIndex,
                        });
                        return;
                    }
                    //return;
                }
                if (this.commandColumns.length > 0) {
                    if (!args.target.ariaLabel && args.target.ej2_instances && args.target.ej2_instances.length > 0) {
                        return;
                    }
                }
                if (this.target_page) {
                    this.$router.push({ name: this.target_page, params: { record_id: args.data?.id?.toString() } });
                } else {
                    this.$emit('rowSelected', args.data);
                }
            },
            pushToSelectedRecords(data) {
                if (data.length) {
                    // Sometimes this can be an array, like when the select all checkbox is selected
                    data.forEach((d) => this.pushToSelectedRecords(d));
                }
                // Check that the data is not already in the selectedRecords
                if (this.findIndexOfRecordInSelectedRecords(data) === -1) {
                    this.selectedRecords.push(data);
                }
            },
            findIndexOfRecordInSelectedRecords(data) {
                for (let selectedRecordI in this.selectedRecords) {
                    if (this.objectsMatch(this.selectedRecords[selectedRecordI], data)) {
                        return Number(selectedRecordI);
                    }
                }
                return -1;
            },
            objectsMatch(obj1, obj2) {
                let obj1Keys = Object.keys(obj1);
                let matchingKeyCount = 0;

                for (let obj1Key of obj1Keys) {
                    if (obj2[obj1Key] && obj2[obj1Key] === obj1[obj1Key]) {
                        matchingKeyCount++;
                    }
                }

                return matchingKeyCount === obj1Keys.length && obj1Keys.length === Object.keys(obj2).length;
            },
            onRowDeselected(args) {
                if (args.data.length && args.isHeaderCheckboxClicked) {
                    /*
                        args.isHeaderCheckboxClicked == True when the deselection occurs due to the header checkbox being toggled
                        args.isHeaderCheckboxClicked == False when the deselection occurs due to the datasource changing
                    */

                    this.selectedRecords = this.selectedRecords.filter((record) => {
                        for (let d of args.data) {
                            if (this.objectsMatch(d, record)) {
                                return false;
                            }
                        }
                        return true;
                    });
                } else if (args.isInteracted) {
                    this.selectedRecords = this.selectedRecords.filter(
                        (record) => !this.objectsMatch(args.data, record)
                    );
                }
            },
            addNewRecord(args) {
                if (this.target_page) {
                    this.$router.push({ name: this.target_page, params: { record_id: 'new' } });
                } else {
                    this.$emit('addNewRecord', args.data);
                }
            },
            clear_criteria() {
                this.searchTextRaw = '';
                this.$emit('cancelOverride');
                this.$store.commit('filters/applyDefaultFilter', this.storeKey);
            },
            //Handles actions
            actionHandler(args) {
                if (args.requestType == 'sorting' && args.type == 'actionBegin') {
                    this.modifyCriteria('sort', { header: args.columnName, direction: args.direction });
                }
            },
            search() {
                this.modifyCriteria('search', { term: this.searchBar });
            },
            redate(data) {
                this.modifyCriteria('date_range', { start_date: data.start_date, end_date: data.end_date });
            },
            applyColumns() {},
            debounceSearch: debounce(function () {
                const newCriteria = { ...this.criteria };
                this.$emit('cancelOverride');

                if (this.searchTextRaw) {
                    newCriteria.search = { like_all: this.searchTextRaw };
                } else {
                    newCriteria.search = {};
                }

                newCriteria.page = {
                    ...newCriteria.page,
                    page_num: 1,
                };

                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: newCriteria,
                });
                this.$refs.searchInput?.focus();
            }, 500),
            async modifyCriteria(type, data) {
                const newCriteria = { ...this.criteria };
                switch (type) {
                    case 'column':
                        newCriteria.column = data;
                        this.doSort();
                        this.openColumn = false;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'sort':
                        const { header, direction } = data;
                        const [sortKey] = Object.keys(newCriteria.sort);

                        // Do nothing if no sort currently applied and the sort command is empty
                        if (header === undefined && sortKey === undefined) {
                            break;
                        }

                        // Do nothing if list is already sorted in the way described by the sort command
                        if (header === sortKey && newCriteria.sort[sortKey] === direction) {
                            break;
                        }

                        if (data.header && data.direction) {
                            newCriteria.sort = { [data.header]: data.direction };
                        } else {
                            newCriteria.sort = {};
                        }

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'pagination':
                        const { num_per_page, page_num } = data;
                        newCriteria.page = { num_per_page, page_num };

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'filter':
                        this.openFilter = false;
                        newCriteria.filter = data;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'search':
                        if (data.term) {
                            newCriteria.search = { like_all: data.term };
                        } else {
                            newCriteria.search = {};
                        }

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'date_range':
                        newCriteria.date_range = data;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    default:
                        break;
                }
            },

            getStoreData() {
                this.searchTextRaw = this.searchText;
                this.ready = true;
            },
            doSort() {
                this.sort = this.criteria.sort;
                let sortTemp = null;
                if (typeof this.sort == 'object' && Object.keys(this.sort).length !== 0) {
                    sortTemp = this.sort;
                }
                if (sortTemp == null) {
                    this.sortOptions = {};
                } else {
                    let direction = sortTemp[Object.keys(sortTemp)[0]];
                    let field = Object.keys(sortTemp)[0];
                    this.sortOptions = { columns: [{ field: field, direction: direction }] };
                }
            },
            handleDataBound() {
                if (this.searchTextRaw) {
                    this.$refs.searchInput.focus();
                }
            },
            handleBlur(e) {
                if (e.relatedTarget?.classList?.contains('e-focus')) {
                    this.$refs.searchInput?.focus();
                }
            },
            async export2exel() {
                await this.exportApi('xls');
            },
            async export2csv() {
                await this.exportApi('csv');
            },
            async exportCredentials2csv() {
                let roleId = JSON.parse(localStorage.getItem('cn-user')).role_id;

                if ([Roles.ADMIN, Roles.SUPER_ADMIN].includes(roleId)) {
                    try {
                        let name = 'credentils_report.csv';
                        const res = await this.$api.get('clients/clientCredentials').then((res) => {
                            if (res.status == 200) {
                                const blob = new Blob([res.data], { type: 'data:text/csv;charset=utf-8,' });
                                const blobURL = window.URL.createObjectURL(blob);
                                const anchor = document.createElement('a');
                                anchor.download = name;
                                anchor.href = blobURL;
                                anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
                                anchor.click();
                            } else {
                                this.$toasted.error(
                                    `Credentials file does not exist on server. Please reset any member's password to create file.`
                                );
                            }
                        });
                    } catch (err) {
                        this.$toasted.error('Failed to fetch clients credentials.');
                    }

                    this.$nextTick(() => {
                        this.loading = false;
                    });
                } else {
                    this.$toasted.error('You do not have access to get clients credentials.');
                }
            },
            async exportApi(type) {
                if (this.export_api && this.export_api != '/invalid_api') {
                    const exp_source = await this.export_body();

                    if (exp_source) {
                        if (exp_source[0]) {
                            this.exportSource(exp_source, type);
                        }
                    }
                } else {
                    //Default source if no export prop is found
                    if (this.data_source[0]) {
                        this.exportSource(this.data_source, type);
                    }
                }
            },
            exportSource(source, type) {
                let sresult = this.autoFormatExport(source);
                let headers = Object.entries(sresult[0]);
                //Get column names of the initial criteria, excluding the id
                let c_meta = this.column_meta
                    .filter((v) => !(v['field'] === 'id' || ('hide' in v && v.hide)))
                    .map((v) => v['field']);
                //Get and filter column headers based on current criteria
                let csv =
                    headers
                        .filter((v) => c_meta.includes(v[0]) && !this.criteria?.column?.includes(v[0]))
                        .map((v) => v[0])
                        .join(type === 'xls' ? '\t' : ',') + '\n';
                //List rows of results based on current and filtered criteria
                sresult.forEach((row) => {
                    csv +=
                        Object.entries(row)
                            .filter((v) => c_meta.includes(v[0]) && !this.criteria?.column?.includes(v[0]))
                            .map((v) => v[1])
                            .join(type === 'xls' ? '\t' : ',') + '\n';
                });
                const format_type =
                    type === 'xls' ? 'application/vnd.ms-excel;charset=utf-8' : 'text/csv;charset=utf-8';
                this.export2file(csv, format_type, type);
            },
            export2file(content, data_type, ext) {
                const anchor = document.createElement('a');
                anchor.href = 'data:' + data_type + ',' + encodeURIComponent(content);
                anchor.target = '_blank';
                anchor.download = this.storeKey + '.' + ext;
                anchor.click();
            },
            printList() {
                window.print();
            },
            update_criteria_props(newVal, oldVal) {
                // if (this.target_page) {
                //     this.$store.commit('filters/update', {
                //         criteriaPage: this.target_page,
                //         payload: this.criteria,
                //     });
                // }
            },
            async export_body() {
                const body = {
                    criteria: this.criteria,
                };
                const body2 = this.$clone_obj(body);
                body2.criteria.page.num_per_page = 10000;
                body2.criteria.page.page_num = 1;

                try {
                    if (this.export_api == '/ledger/transactions') {
                        const res3 = await this.$api.post(ledger.getTransactionList(), {
                            criteria: {
                                ...body2.criteria,
                                filter: {
                                    ...(this.criteria.filter || {}),
                                    'ledger.client_id': [this.client_id.toString()],
                                },
                            },
                            clientId: this.client_id,
                        });
                        const rows3 = res3.data.rows.map((row) => {
                            return {
                                id: row.id,
                                dayt_create: dayjs(row.date).format('MM/DD/YYYY'),
                                description: row.description,
                                charge_total: row.charge_total,
                                payment_total: row.payment_total,
                                type: row.type,
                                rawPayment: row.payment,
                                paymentType: row.paymentType,
                                paymentIntentId: row.stripePaymentIntentId,
                                availableForRefund: row.availableForRefund,
                            };
                        });
                        return rows3;
                    } else {
                        const res2 = await this.$api.post(this.export_api, body2);
                        return res2.data.rows;
                    }
                } catch (e) {
                    //Error: Invalid api root
                }

                return null;
            },
            async fetchTags() {
                try {
                    let res = await this.$api.get(companies.getTags(this.$store.state.user.company_id));
                    if (res.data.success) {
                        let filters = this.pageMeta.filters;
                        filters = {
                            status: filters.status,
                            tags: {
                                ...filters.tags,
                                options: res.data.info.map((item) => item.name),
                            },
                        };
                        this.pageMeta.filters = JSON.stringify(filters);
                    }
                } catch (err) {
                    console.log('err', err);
                }
            },
            queryCellInfoHandler(args) {
                const field = args.column.field;
                const cellData = args.data[field];

                if (field === 'dashboardNames' || field === 'tags') {
                    const data = [...new Set(Object.values(cellData).filter((item) => item !== null))];

                    const content = data.join(', ');

                    const {
                        dashboardUsed,
                        kiosk_dashboard_used,
                        mhh_dashboard_used,
                        family_connect_dashboard_used,
                        star_kid_dashboard_used,
                        mobex_plus_dashboard_used,
                        mobex_health_home_dashboard_used,
                    } = cellData;

                    let tooltipContent = [
                        dashboardUsed && `Dashboard: ${dashboardUsed}`,
                        kiosk_dashboard_used && `Kiosk: ${kiosk_dashboard_used}`,
                        mhh_dashboard_used && `MHH: ${mhh_dashboard_used}`,
                        star_kid_dashboard_used && `Star-kids: ${star_kid_dashboard_used}`,
                        mobex_plus_dashboard_used && `Mobex-plus: ${mobex_plus_dashboard_used}`,
                        mobex_health_home_dashboard_used && `Mobex-health-home: ${mobex_health_home_dashboard_used}`,
                        family_connect_dashboard_used && `Family-connect: ${family_connect_dashboard_used}`,
                    ]
                        .filter(Boolean)
                        .join('\n');

                    // Set the cell's text content and the tooltip content
                    args.cell.innerText = content;
                    args.cell.setAttribute('title', field === 'tags' ? content : tooltipContent);
                }
            },
        },
        created() {
            this.getStoreData();
            this.init();
            this.fetchTags();
        },
        provide: {
            grid: [Sort, VirtualScroll, CommandColumn],
        },
        watch: {
            searchTextRaw() {
                this.debounceSearch();
            },
            data_source: function (newDataSource) {
                let indexesThatShouldBeSelected = [];

                // Compare selected records to the new data source and find all matches.
                for (let i in newDataSource) {
                    if (this.findIndexOfRecordInSelectedRecords(newDataSource[i]) !== -1) {
                        indexesThatShouldBeSelected.push(i);
                    }
                }
                /*
                    This timeout is used to wait for the grid to finish updating with the new datasource before selecting the rows.
                    Getting the element by class name and clicking it is hacky, but syncfusion sucks and this was the only
                    way I could find of selecting the row without having the user click twice to unselect it again.
                */
                setTimeout(
                    () =>
                        indexesThatShouldBeSelected.forEach((i) =>
                            document.getElementsByClassName('e-gridchkbox')[i].click()
                        ),
                    100
                );
            },
        },
    };
</script>

<style>
    div.list-header-right div.actions-wrap div.show_actions_drop_down {
        visibility: visible;
    }
</style>
