var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 my-5"},[_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"bg-white rounded-4 border mx-2 widget-list overflow-auto",staticStyle:{"height":"630px"}},[_c('h1',{staticClass:"px-4 py-3 heading"},[_vm._v("Selected Modules widgets")]),_c('draggable',{staticClass:"p-4 widgets-container",attrs:{"list":_vm.widgetsList,"group":{ name: 'myGroup', pull: true, put: false }},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd}},_vm._l((_vm.widgetsList),function(element,index){return _c('div',{key:index,staticClass:"col-4 d-flex justify-content-center"},[(element.function_type === '3rdParty')?_c('div',{staticClass:"widget widget-title d-flex justify-content-center align-items-center flex-column"},[_c('img',{staticClass:"m-auto",staticStyle:{"max-height":"6rem"},attrs:{"src":element.image,"alt":""}}),_c('p',[_vm._v(_vm._s(element.function_name))])]):_c('div',{staticClass:"widget",style:({
                            'background-color': element.function_name === 'Photos' ? '#5155c3f2' : '',
                        })},[_c('img',{staticClass:"m-auto",attrs:{"src":element.image,"alt":""}})])])}),0)],1),_c('div',{staticClass:"bg-white rounded-4 border mx-2 dash-builder w-50 over",staticStyle:{"height":"630px","overflow-y":"auto"}},[_vm._m(0),(_vm.template)?_c('div',{staticClass:"droppable-template"},[_c('div',{staticClass:"primeContainer"},[_vm._m(1),_c('div',{staticClass:"t-m-c templateMainContainer",staticStyle:{"margin-top":"5rem"}},[_c('div',{staticClass:"module-heading"},[_vm._v("Offered Services")]),_c('div',{staticClass:"u-l-c upperLayoutContainer"},_vm._l((Object.entries(_vm.template.LI)),function(item,index){return _c('div',{key:item[1].name + '',class:'upperLayoutContainerItemCommon ' +
                                    item[1].class +
                                    (_vm.width && _vm.height && !item[1].elements.length ? ' highlighted-section ' : ''),style:(item[1].style)},[_c('draggable',{staticClass:"draggable-list",attrs:{"list":_vm.template['LI'][item[0]].elements,"group":_vm.template['LI'][item[0]].elements.length
                                            ? { name: 'myGroup', pull: true, put: false }
                                            : { name: 'myGroup', pull: true, put: true },"options":{ accept: false }},on:{"start":_vm.onMoveStart,"end":_vm.onMoveEnd,"change":function($event){return _vm.manipulateWidgets($event, 'LI', item[1].name, 'add', item, index)}}},_vm._l((_vm.template['LI'][item[0]].elements),function(value){return _c('div',{key:value.name,staticClass:"module",class:value.class,on:{"mouseover":function($event){_vm.hoveredElementId = 'LI' + index},"mouseleave":function($event){_vm.hoveredElementId = null},"click":function($event){return _vm.addDetails(value.function_name, index, 'LI')}}},[(value.function_type == 'generic')?_c('div',{staticClass:"widget-img",style:({
                                                height: _vm.template['LI'][item[0]].title.length == 0 ? '100%' : '7rem',
                                            })},[_c('img',{attrs:{"src":value.image,"alt":""}})]):(value.function_type == '3rdParty')?_c('div',{staticClass:"widget-img",staticStyle:{"height":"7rem"}},[_c('img',{attrs:{"src":value.image,"alt":""}})]):_c('div',{staticClass:"widget-img h-100",style:({
                                                'background-color':
                                                    value.function_name === 'Photos' ? '#5155c3f2' : '',
                                                'border-radius': '2rem',
                                            })},[_c('img',{attrs:{"src":value.image,"alt":""}})]),_c('div',{staticClass:"widget-title"},[(
                                                    value.function_type == 'generic' &&
                                                    _vm.template['LI'][item[0]].title
                                                )?_c('p',[_vm._v(" "+_vm._s(_vm.template['LI'][item[0]].title)+" ")]):(value.function_type == '3rdParty')?_c('p',[_vm._v(" "+_vm._s(value.function_name)+" ")]):_vm._e()]),(_vm.hoveredElementId == 'LI' + index)?[_c('div',{staticClass:"w-100 h-100 action-buttons"},[_c('span',{staticClass:"background",attrs:{"id":"delete"},on:{"click":function($event){return _vm.manipulateWidgets(
                                                            $event,
                                                            'LI',
                                                            item[1].name,
                                                            'remove',
                                                            value,
                                                            index
                                                        )}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/delete-icon.png"),"alt":""}})]),_c('b-tooltip',{attrs:{"target":"delete","triggers":"hover","placement":"bottom"}},[_vm._v("Delete")]),(value.function_type == 'generic')?_c('span',{staticClass:"background",attrs:{"id":"edit"},on:{"click":function($event){return _vm.OpenEditModal(value.function_name, index, 'LI')}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/edit-icon.png"),"alt":""}})]):_vm._e(),_c('b-tooltip',{attrs:{"target":"edit","triggers":"hover","placement":"bottom"}},[_vm._v("Edit")])],1)]:_vm._e()],2)}),0)],1)}),0)]),_c('div',{staticClass:"t-m-c templateMainContainer",staticStyle:{"padding":"0 2rem 2rem"}},[_c('div',{staticClass:"module-heading"},[_vm._v("Informational Links")]),_c('div',{staticClass:"u-l-c upperLayoutContainer"},_vm._l((Object.entries(_vm.template.GSI)),function(item,index){return _c('div',{key:item[1].name + '',class:'upperLayoutContainerItemCommon ' +
                                    item[1].class +
                                    (_vm.width && _vm.height && !item[1].elements.length ? ' highlighted-section ' : ''),style:(item[1].style)},[_c('draggable',{staticClass:"draggable-list",attrs:{"list":_vm.template['GSI'][item[0]].elements,"group":_vm.template['GSI'][item[0]].elements.length
                                            ? { name: 'myGroup', pull: true, put: false }
                                            : { name: 'myGroup', pull: true, put: true },"options":{ accept: false }},on:{"start":_vm.onMoveStart,"end":_vm.onMoveEnd,"change":function($event){return _vm.manipulateWidgets($event, 'GSI', item[1].name, 'add', item, index)}}},_vm._l((_vm.template['GSI'][item[0]].elements),function(value){return _c('div',{key:value.name,staticClass:"module",class:value.class,on:{"mouseover":function($event){_vm.hoveredElementId = 'GSI' + index},"mouseleave":function($event){_vm.hoveredElementId = null},"click":function($event){return _vm.addDetails(value.function_name, index, 'GSI')}}},[(value.function_type == 'generic')?_c('div',{staticClass:"widget-img",style:({
                                                height:
                                                    _vm.template['GSI'][item[0]].title.length == 0 ? '100%' : '7rem',
                                            })},[_c('img',{attrs:{"src":value.image,"alt":""}})]):(value.function_type == '3rdParty')?_c('div',{staticClass:"widget-img",staticStyle:{"height":"7rem"}},[_c('img',{attrs:{"src":value.image,"alt":""}})]):_c('div',{staticClass:"widget-img h-100",style:({
                                                'background-color':
                                                    value.function_name === 'Photos' ? '#5155c3f2' : '',
                                                'border-radius': '2rem',
                                            })},[_c('img',{attrs:{"src":value.image,"alt":""}})]),_c('div',{staticClass:"widget-title"},[(
                                                    value.function_type == 'generic' &&
                                                    _vm.template['GSI'][item[0]].title
                                                )?_c('p',[_vm._v(" "+_vm._s(_vm.template['GSI'][item[0]].title)+" ")]):(value.function_type == '3rdParty')?_c('p',[_vm._v(" "+_vm._s(value.function_name)+" ")]):_vm._e()]),(_vm.hoveredElementId == 'GSI' + index)?[_c('div',{staticClass:"w-100 h-100 action-buttons"},[_c('span',{staticClass:"background",attrs:{"id":"delete"},on:{"click":function($event){return _vm.manipulateWidgets(
                                                            $event,
                                                            'GSI',
                                                            item[1].name,
                                                            'remove',
                                                            value,
                                                            index
                                                        )}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/delete-icon.png"),"alt":""}})]),_c('b-tooltip',{attrs:{"target":"delete","triggers":"hover","placement":"bottom"}},[_vm._v("Delete")]),(value.function_type == 'generic')?_c('span',{staticClass:"background",attrs:{"id":"edit"},on:{"click":function($event){return _vm.OpenEditModal(value.function_name, index, 'GSI')}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/edit-icon.png"),"alt":""}})]):_vm._e(),_c('b-tooltip',{attrs:{"target":"edit","triggers":"hover","placement":"bottom"}},[_vm._v("Edit")])],1)]:_vm._e()],2)}),0)],1)}),0)])])]):_vm._e()])]),_c('div',{staticClass:"addmoduledetail"},[(_vm.openEditModal)?_c('AddDetailModal',{attrs:{"open":_vm.openEditModal,"clickedProfileFunction":_vm.clickedProfileFunction,"position":_vm.position,"pageData":_vm.pageData,"applicationFrame":_vm.applicationFrame,"group":_vm.group},on:{"close":function($event){_vm.openEditModal = false}}}):_vm._e(),(_vm.isEducation)?_c('EducationFeedEditModal',{attrs:{"dashboardId":_vm.dashboardId,"position":_vm.position,"pageData":_vm.pageData,"applicationFrame":_vm.applicationFrame,"clickedProfileFunction":_vm.clickedProfileFunction},on:{"cancel":function($event){_vm.isEducation = false}}}):_vm._e()],1),(_vm.loading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h1',{staticClass:"px-4 py-3 heading w-100"},[_vm._v("Dashboard Builder")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-top-bar"},[_c('div',{staticClass:"inner-dashboard-top-bar"},[_c('div',{staticClass:"d-flex w-10 justify-content-start"},[_c('div',{staticClass:"d-flex justify-content-start ms-3"},[_c('img',{staticStyle:{"width":"30px","height":"30px","filter":"invert(1) grayscale(100%) brightness(200%)"},attrs:{"src":require("@/assets/px/drag-and-drop/menu-icon.svg"),"alt":""}})])]),_c('div',{staticClass:"w-60 d-flex fw-semibold fs-4 text-light"},[_vm._v("Dashboard")]),_c('div',{staticClass:"d-flex w-25 gap-3 justify-content-end align-items-center"},[_c('div',{staticClass:"rounded-3 p-sm-1",staticStyle:{"background-color":"#3a88bd"}},[_c('img',{staticStyle:{"height":"25px","width":"25px"},attrs:{"src":require("@/assets/px/header-icons-images/femaleUserIcon.png"),"alt":""}})]),_c('div',{staticClass:"rounded-3 p-sm-1",staticStyle:{"background-color":"#3a88bd"}},[_c('img',{staticStyle:{"height":"25px","width":"25px"},attrs:{"src":require("@/assets/px/header-icons-images/globe-language.svg"),"alt":""}})]),_c('img',{staticStyle:{"height":"25px","width":"25px","filter":"invert(1) grayscale(100%) brightness(200%)"},attrs:{"src":require("@/assets/px/header-icons-images/notification.svg"),"alt":""}})])]),_c('div',{staticClass:"dashboard-header"},[_vm._v(" Hello, Adam Liviston "),_c('img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":require("@/assets/px/header-icons-images/hello.svg"),"alt":""}})])])}]

export { render, staticRenderFns }