import { progressNotes } from '@/util/apiRequests';
import {camelCase, merge, snakeCase} from 'lodash';
const portal_data_snake_case_ok_fields = [
    'first_name','middle_name','last_name','dob','drivers_license_number','street_address','street_address2','city','state','zip',
    'race', 'religion','gender','identified_gender','primary_phone','alt_phone','education','disability','veteran_status',
    'contact_methods','means_of_income','employment_status','annual_income_dollars','preferred_language'
]
export const dryalex = {
    async form_data_init(xthis) {
        // xthis.component_name = xthis.$options.name // - not reliable for multiple routes to same component
        if (xthis.record_id == 0 || xthis.record_id == '0' || xthis.record_id == 'new') {
            xthis.this_record_id = 0;
            const res = await xthis.$api.get(`/form_data/pages_form_schema/${xthis.pageMeta.id}`);
            if (res.data.info[0]) {
                xthis.user_id = xthis.$store.state.user.id;
                if (parseInt(xthis.$store.state.current_client_id)) {
                    xthis.client_id = parseInt(xthis.$store.state.current_client_id);
                    window.localStorage.setItem('last_client_id', xthis.client_id);
                    xthis.$store.state.current_client_id = xthis.client_id;
                } else {
                    if (window.localStorage.getItem('last_client_id') && parseInt(window.localStorage.last_client_id)) {
                        xthis.client_id = parseInt(window.localStorage.last_client_id);
                        xthis.$store.state.current_client_id = xthis.client_id;
                    }
                }
                xthis.page_id = xthis.pageMeta.id;
                xthis.rec = JSON.parse(res.data.info[0].form_schema);
                xthis.original_rec = JSON.parse(res.data.info[0].form_schema);
                xthis.signature_ids = [];

                if(xthis.pageMeta.component_name == 'ProgressNote'){
                    xthis.rec.client_id = xthis.client_id;
                    //xthis.rec.counselors = JSON.parse(xthis.apptData.counselors)
                    // try { xthis.rec.counselors = JSON.parse(xthis.apptData.counselors) } catch (e) { xthis.rec.counselors = [] }
                    // if(xthis.rec.counselors.length > 0){
                    //     xthis.rec.counselors[0].isPrimary = true;
                    // }
                }
                // try{ this.signature_ids = JSON.parse(res.data.info[0].signature_ids) ? JSON.parse(res.data.info[0].signature_ids) : []}catch(error){/**/}
            }
        } else {
            xthis.this_record_id = parseInt(xthis.record_id);
            const res = await this.get_form_data(xthis)
            if (res.status === 404 || res.status === 500) {
                xthis.$toasted.error('Could not find record');
                return xthis.$router.replace({ name: 'NotFound' });
            } else {
                if (res.data.info[0]) {
                    xthis.user_id = res.data.info[0].user_id;
                    xthis.client_id = parseInt(res.data.info[0].client_id);

                    window.localStorage.setItem('last_client_id', xthis.client_id);
                    xthis.$store.state.current_client_id = xthis.client_id;

                    // xthis.$store.state.current_client_id = xthis.client_id
                    xthis.page_id = res.data.info[0].page_id;
                    switch(xthis.pageMeta.component_name){
                        default:
                            xthis.rec = JSON.parse(res.data.info[0].raw_data);
                            xthis.original_rec = JSON.parse(res.data.info[0].raw_data);
                            break;
                        case 'ProgressNote':
                            // data comes back as object, so no need to parse from DB
                            xthis.rec = res.data.info[0];
                            //still need to parse other nested objects
                            xthis.rec.diags = JSON.parse(xthis.rec.diags);
                            xthis.rec.counselors = JSON.parse(xthis.rec.counselors);
                            xthis.rec.assessment = JSON.parse(xthis.rec.assessment);
                            xthis.rec.risks = JSON.parse(xthis.rec.risks);
                            xthis.rec.summary = JSON.parse(xthis.rec.summary);
                            xthis.rec.interventions = JSON.parse(xthis.rec.interventions);
                            xthis.rec.objectives = JSON.parse(xthis.rec.objectives);
                            xthis.rec.goals = JSON.parse(xthis.rec.goals);
                            xthis.rec.services = JSON.parse(xthis.rec.services);
                            xthis.rec.place_of_service = JSON.parse(xthis.rec.place_of_service);
                            xthis.original_rec = res.data.info[0];
                            xthis.rec.signature_ids = JSON.parse(xthis.rec.signature_ids);
                            xthis.signature_ids = xthis.rec.signature_ids;

                            // handles weird refresh with signature_ids.length issue after edit addendum is clicked
                            xthis.rec.signature_ids =  xthis.rec.signature_ids == null ? [] : xthis.rec.signature_ids;
                            xthis.signature_ids =  xthis.signature_ids == null ? [] : xthis.rec.signature_ids;

                            //handles thoughtProcess attribute on assessment
                            if(xthis.rec.assessment.thoughtProcess){
                                xthis.rec.assessment.thought = xthis.rec.assessment.thoughtProcess;
                                delete xthis.rec.assessment.thoughtProcess;
                            }
                            break;
                    }
                    xthis.created_at = res.data.info[0]?.dayt_create;
                    xthis.updated_at = res.data.info[0]?.dayt_mod;

                    if (res.data.info[0].signature_ids && xthis.pageMeta.component_name !== 'ProgressNote') {
                        try {
                            xthis.signature_ids = JSON.parse(res.data.info[0].signature_ids)
                                ? JSON.parse(res.data.info[0].signature_ids)
                                : [];
                        } catch (error) {
                            /**/
                        }
                    }
                }
            }
        }
        xthis.original_signature_ids = xthis.signature_ids;
        return xthis;
    },
    async get_form_data(xthis){
        // if we are looking at the Progress Note...
        if(xthis.pageMeta.component_name == 'ProgressNote'){
            return await xthis.$api.get(progressNotes.getProgressNote(xthis.this_record_id));
        }
        // if we are looking at any other form...
        return await xthis.$api.get(`/form_data/${xthis.this_record_id}`);
    },
    async form_data_update_record(xthis, e, sign_n_save_bs = 0) {
        const is_valid = await this.validate_form(xthis, e);
        if (is_valid) {
            try {
                if (!sign_n_save_bs) {
                    xthis.user_id = xthis.$store.state.user.id;
                }
                let update_record_data = {
                    user_id: xthis.user_id,
                    client_id: parseInt(xthis.client_id),
                    page_id: xthis.pageMeta.id,
                    raw_data: xthis.rec,
                    signatures_needed: xthis.signatures_needed
                };
                if (sign_n_save_bs) {
                    delete(update_record_data.signatures_needed)
                }
                //need to handle use case where we are updating a progress note
                let res

                switch (xthis.pageMeta.component_name) {
                    case 'ProgressNote':
                        //set appt_id and page_id for backend functions
                        xthis.rec.appt_id = xthis.apptData.id;
                        xthis.rec.page_id =  xthis.pageMeta.id
                        xthis.rec.is_addendum = xthis.isAddendum

                        //make api call to update or "save" progress note
                        res = await xthis.$api.put(`/notes/${xthis.this_record_id}`, xthis.rec);
                        if (res) {
                            xthis.$toasted.success('Successfully updated Record.');

                            //TODO: put this on the backend
                            //grab the updated record_id from the backend if progress note is "new"
                            const record_id = parseInt(res.data.recId[0]);
                            if (record_id) {
                                xthis.this_record_id = record_id;
                                xthis.original_rec = xthis.$clone_obj(xthis.rec);
                                xthis.router_push = 0;
                                xthis.updating = 0;
                                xthis.signature_ids = [];
                                xthis.signatures_info = [];

                                try {
                                    await xthis.$router.replace({
                                        name: xthis.pageMeta.component_name,
                                        params: { id: xthis.this_record_id }
                                    });
                                } catch (err) {
                                    xthis.router_push = 1;
                                }
                            }

                            //handles existing PN records
                            //  this was added to fix "sign" button not showing up after users:
                            //  update a record, sign it, create addendum and save it,
                            //  then resign it. A temp solution, but it works.

                            //TODO: put this on the backend
                            if (xthis.this_record_id !== 0) {
                                xthis.original_rec = xthis.$clone_obj(xthis.rec);
                                xthis.router_push = 0;
                                xthis.updating = 0;
                                xthis.signature_ids = [];
                                xthis.signatures_info = [];

                                try {
                                    await xthis.$router.replace({
                                        name: xthis.pageMeta.component_name,
                                        params: { id: xthis.this_record_id }
                                    });
                                } catch (err) {
                                    xthis.router_push = 1;
                                }
                            }

                        }
                        break;

                    default:
                        res = await xthis.$api.put(`/form_data/${xthis.this_record_id}`, update_record_data);
                        if (!sign_n_save_bs && res){
                            xthis.$toasted.success('Successfully updated Record.');
                            const record_id = parseInt(res.data.recId[0]);
                            if (record_id) {
                                xthis.this_record_id = record_id;
                                xthis.original_rec = xthis.$clone_obj(xthis.rec);
                                xthis.router_push = 0;
                                xthis.updating = 0;
                                xthis.signature_ids = [];
                                xthis.signatures_info = [];
                                try {
                                    await xthis.$router.replace({
                                        name: xthis.pageMeta.component_name,
                                        params: { record_id: xthis.this_record_id }
                                    });
                                } catch (err) {
                                    xthis.router_push = 1;
                                }
                            }
                        }
                        break
                }
            } catch (err) {
                xthis.$toasted.error('Failed to update Record. Please try again later.');
                xthis.updating = 0;
            }
            xthis.updating = 0;
            xthis.original_signature_ids = xthis.signature_ids;
        }
        return xthis;
    },
    async validate_form(xthis, e) {
        const isValid = 1; // await this.$refs.form.validate()
        if (!isValid) {
            xthis.$toasted.error('Please fill out all required fields.');
        }
        return isValid;
    },
    async do_save_portal_data(xthis) {
        let data = xthis.generalInfo;
        if (xthis.contactInfo && xthis.contactInfo.contact_methods) {
            data.contact_methods = xthis.$clone_obj(xthis.contactInfo.contact_methods)
            data.byemail = data.contact_methods.byemail
            data.byphone = data.contact_methods.byphone
        }

        xthis.portal_data.data = xthis.$clone_obj(data)
        xthis.portal_data.original_data = xthis.$clone_obj(data)

        let res = await xthis.$api.get(`/clients/${xthis.clientId}/general-info`);
        let result = 0
        if (res + res.data) {
            result = res.data
        }
        if (result[0] && result[0].portal_data && result[0].portal_data) {
            let snake_case_updated = result[0].portal_data;
            xthis.portal_data.snake_case = merge(xthis.portal_data.snake_case, snake_case_updated)
        }
        for (let [k2, v] of Object.entries(data)) {
            let k = snakeCase(k2)
            if (k == 'street_address_2') {
                k = 'street_address2';
            }
            if (portal_data_snake_case_ok_fields.indexOf(k) != -1) {
                xthis.portal_data.snake_case[k] = v
            }
        }
        if (xthis.portal_data.snake_case.contact_methods) {
            delete (xthis.portal_data.snake_case.contact_methods.notes)
        }
        for (let [k, v] of Object.entries(xthis.portal_data.snake_case)) { // clean bad entries
            if (portal_data_snake_case_ok_fields.indexOf(k) == -1){
                delete(xthis.portal_data.snake_case[k])
            }
        }
        data.portal_data = JSON.stringify(xthis.portal_data.snake_case)
        xthis.merge_portal_button = 0;
        xthis.portal_data.pokeme ++;
        return xthis
    },
    async do_merge_portal_data(xthis) {
        let data = xthis.generalInfo;
        if (xthis.portal_data && xthis.merge_portal_button) {
            if (xthis.merge_portal_button == 2) {
                data = merge(data, xthis.portal_data.data)
            } else {
                data = merge(data, xthis.portal_data.original_data)
            }
            xthis.merge_portal_button = 3 - xthis.merge_portal_button
        }
        xthis.portal_data.pokeme ++
        return xthis
    },
    async do_portal_data(result = [], xthis) {
        let data = xthis.generalInfo;
        // all of portal_data is snake_case, and all of generlInfo is retarded camelCase
        let merge_portal_button = 0
        xthis.portal_data.data = xthis.$clone_obj(data)
        delete(xthis.portal_data.data['id'])
        delete(xthis.portal_data.data['email'])
        xthis.portal_data.original_data = xthis.$clone_obj(xthis.portal_data.data)
        if (result[0] && result[0].portal_data && result[0].portal_data) {
            xthis.portal_data.snake_case = result[0].portal_data;
            for (let [k, v] of Object.entries(xthis.portal_data.snake_case)) { // clean bad entries
                if (portal_data_snake_case_ok_fields.indexOf(k) == -1){
                    delete(xthis.portal_data.snake_case[k])
                }
            }
            if (xthis.portal_data.snake_case.contact_methods) {
                if (xthis.portal_data.snake_case.contact_methods.byemail) {
                    xthis.portal_data.snake_case.byemail = xthis.portal_data.snake_case.contact_methods.byemail
                }
                if (xthis.portal_data.snake_case.contact_methods.byphone) {
                    xthis.portal_data.snake_case.byphone = xthis.portal_data.snake_case.contact_methods.byphone
                }
            }

            for (let [k, v] of Object.entries(xthis.portal_data.snake_case)) {
                let k2 = camelCase(k)
                if (k != 'id' && Object.prototype.hasOwnProperty.call(data, k2)) {
                    if (v) {
                        xthis.portal_data.data[k2] = v // map only fields in this component
                    }
                    if (v && !data[k2]) { // auto add new portal_data if client data is blank
                        // data[k2] = v`
                    }
                    if (v && v != data[k2]) {
                        merge_portal_button = 2
                    }
                }
            }
        } else { // aint no portal_data at all
            for (let [k2, v] of Object.entries(data)) {
                let k = snakeCase(k2)
                if (portal_data_snake_case_ok_fields.indexOf(k) != -1) {
                    xthis.portal_data.snake_case[k] = v
                }
            }
        }
        xthis.merge_portal_button = merge_portal_button
        xthis.portal_data.pokeme ++
        return xthis
    },
};
